import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonService } from './common/common.service';
import { CustomFormsModule } from 'ng2-validation';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'tour-of-heroes' }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CustomFormsModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [CommonService],
  bootstrap: [ AppComponent ]
})
export class AppModule { }


