import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router, NavigationEnd } from "@angular/router";
import { delay, map } from 'rxjs/operators';
// import { Constants } from './constants';
import { Observable, of, Subject } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
declare var $:any;
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class CommonService {
    private _apiUrl;
    public _siteUrl:any;
    private _apiWordpressUrl;
    router : Router;
     private previousUrl: string;
    private currentUrl: string;
    constructor(@Inject(DOCUMENT) private doc, private _meta: Meta, private _title: Title, router : Router, @Inject(PLATFORM_ID) platformId: Object,private _http: HttpClient, 
      ) {
    this.router = router;
    // this._apiUrl = constants.getApiUrl();
    // this._apiWordpressUrl = constants.getWordPressApiUrl();
    this.platformId = platformId;

    this.currentUrl = this.router.url;
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {        
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        };
      });

      // this._siteUrl = constants.getApiUrl();
    
  }

  public platformId;

   // Observable string sources
  private missionAnnouncedSource = new Subject<object>();
  private missionConfirmedSource = new Subject<object>();
 
  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();
 
  // Service message commands
  ChildToParent(mission: object) {
    this.missionAnnouncedSource.next(mission);
  }
 
  ParentToChild(astronaut: object) {
    this.missionConfirmedSource.next(astronaut);
  }

  getBaseUrl(){
    return this._apiUrl;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }  



  showServerError(e){

  }

  popToast(one, two, three){

  }
  

  

}