import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common/common.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Tour of Heroes';

  subscription: Subscription;

  constructor(private commonService: CommonService, private router: Router, private cdr: ChangeDetectorRef) {
    try {

      $(document).ready(function () {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
        };
      });

      this.subscription = commonService.missionAnnounced$.subscribe(e => {
        try {
          console.log('4454545');
          this.openPopUp(e)
        } catch (error) {
        }
      });

    } catch (error) {
      console.log("error", error)
    }

  }

  ngOnInit() {
    try {
      $(window).on('popstate', $.proxy(function (event) {
        $('.back-main').removeClass('backdiv');
        //console.log("backurl",this.commonService.getPreviousUrl())
        var routeUrl = this.commonService.getPreviousUrl();
        $('body').addClass('backactive');
        $('body').addClass('bodybefore');
        $('.back-main').css('width', $(window).width());
        $('.back-main').css('height', $(window).height());
        $('.back-main').css('top', '0');
        setTimeout($.proxy(function () {
          $('.back-main').css('top', '-100%');
          this.router.navigate([routeUrl]);

        }, this), 500);
        setTimeout(function () {
          $('.back-main').removeAttr('style');
        }, 1000);
        setTimeout(function () {
          $('body').removeClass('backactive');
        }, 1500);
        setTimeout(function () {
          $('.jaben-animation').addClass('jabload');
          $('body').removeClass('bodybefore');
        }, 1600);
      }, this));
    } catch (error) {

    }
  }

  openPopUp(e) {
    try {
      this.showLoader()
    } catch (error) {

    }
  }

  showLoader() {
    try {
      $('.back-main').removeClass('backdiv');

      var routeUrl = this.commonService.getPreviousUrl();
      $('body').addClass('backactive');
      $('body').addClass('bodybefore');
      $('.back-main').css('width', $(window).width());
      $('.back-main').css('height', $(window).height());
      $('.back-main').css('top', '0');
      setTimeout($.proxy(function () {
        $('.back-main').css('top', '-100%');
        this.router.navigate([routeUrl]);
      }, this), 500);
      setTimeout(function () {
        $('.back-main').removeAttr('style');
      }, 1000);
      setTimeout(function () {
        $('body').removeClass('backactive');
      }, 1500);
      setTimeout(function () {
        $('.jaben-animation').addClass('jabload');
        $('body').removeClass('bodybefore');
      }, 1600);
    } catch (error) {
    }
  }

}
